<template>
  <div>
    <ClientHeader :client="client" />
  <v-row>
    <v-col cols="12" class="mt-5 mb-5">
      <v-card
        elevation="3"
        class="d-flex some-style"
      >
        <v-text-field
          class="pl-4"
          v-model="noteInput"
          label="Add note"
        ></v-text-field>
        <v-icon @click="save" class="pr-5" style="cursor: pointer">
          mdi-send
        </v-icon>
      </v-card>
    </v-col>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="cyan"
    ></v-progress-linear>
    <v-col cols="12" v-if="notes.length">
      <v-card
        class="mb-8"
        v-for="(note, index) in notes"
        :key="`${index}-note`"
      >
        <v-row>
          <v-col cols="10" class="text-start d-flex align-center">
            <img
              v-if="note.author_avatar"
              class="defaultImage ml-5"
              :src="note.author_avatar"
              alt="avatar"/>
            <img v-else class="defaultImage" :src="defaultAvatar" alt="defaultAvatar" />
            <span
              v-if="note.author === user.id"
              class="pl-2"
              style="font-size: 1rem; font-weight: 460"
            >
              You
            </span>
            <span v-else class="pl-2" style="font-size: 1rem; font-weight: 460">
              {{ note.author_name }}
            </span>
            <span class="pl-5" style="opacity: 75%; font-size: 0.8rem">{{
              note.date
            }}</span>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-3"  icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    @click="editNote(index)"
                    style="cursor: pointer"
                    >Edit</v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    @click="deleteNote(note.id, index)"
                    style="cursor: pointer"
                    >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-show="!note.isEdit" cols="12" class="pb-12 px-12">
            <p
              class="text-left"
              v-html="note.body"
              style="opacity: 75%; font-weight: 500; font-size: 1rem"
            ></p>
          </v-col>
          <v-col v-show="note.isEdit" cols="12" class="mt-5 mb-5">
            <v-card
              height="48px"
              elevation="0"
              class="d-flex some-style"
              style="position: relative"
            >
              <v-text-field
                outlined
                autofocus
                class="pl-4 mr-3"
                v-on:keyup.enter="patchNote(note.id, note.body, index)"
                v-model="note.body"
                label="Edit note"
              ></v-text-field>
              <v-icon
                @click="patchNote(note.id, note.body, index)"
                class="pr-5"
                style="cursor: pointer"
              >
                mdi-send
              </v-icon>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!--    Empty State Note-->
    <v-col cols="12" v-if="!notes.length & !loading">
      <v-card v-if="notes" class="mb-8">
        <v-row>
          <v-col cols="10" class="text-start">
            <span class="pl-5" style="font-size: 1rem; font-weight: bold"
              >Patriot</span
            >
            <span class="pl-5" style="opacity: 75%; font-size: 0.8rem"></span>
          </v-col>
          <v-col cols="2">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list></v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" class="pb-12 px-12">
            <p
              class="text-left"
              style="opacity: 75%; font-weight: 500; font-size: 1rem"
            >
              To add a note type your message and click the send icon
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import AuthService from "@/services/auth.service";
import ClientsService from "@/services/clients.service";
import defaultAvatar from "@/assets/img/Asset-1.png";
import {mapGetters} from "vuex";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "client-notes",
  components: {ClientHeader},
  data: () => ({
    noteInput: "",
    defaultAvatar: defaultAvatar,
    notes: [],
    loading: false,
    client: null,
    today: new Date().toISOString(),
    user: {}
  }),computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser'
        },
    ),
  },
  methods: {
    editNote(index) {
      this.notes[index].isEdit = true;
      this.notes = [...this.notes];
    },
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
    getNotes() {
      this.loading = true;
      ClientsService.getNotes(this.clientId)
        .then((res) => {
          this.notes = res;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$func.popMessage(`${err.response.data.error}`, "fail");
        });
    },
    deleteNote(id, index) {
      ClientsService.deleteNote(id)
        .then(() => {
          this.notes.splice(index, 1);
          this.$func.popMessage(`Successfully deleted your note`, "success");
        })
        .catch((err) => {
          this.$func.popMessage(`${err.response.data.error}`, "fail");
        });
    },
    patchNote(id, note, index) {
      ClientsService.patchNote(id, note)
        .then(() => {
          this.$func.popMessage(`Successfully edit your note`, "success");
          this.notes[index].isEdit = false;
          this.notes = [...this.notes];
        })
        .catch((err) => {
          this.$func.popMessage(`${err.response.data.error}`, "fail");
        });
    },
    async getUser(){
      await AuthService.getCurrentUser(this.currentUser.current_user_id)
        .then(res=>{
          this.user = res.data
        })
    },
    getDateFormat() {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let time = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let newdate = month + "/" + day + "/" + year + " @ " + time;
      this.today = newdate;
    },
    save() {
      this.loading = true;
      ClientsService.postNote(this.clientId, this.noteInput)
        .then((res) => {
          this.notes.splice(0, 0, res);
          this.loading = false;
          this.noteInput = "";
          this.$func.popMessage(`Successfully saved your note`, "success");
        })
        .catch((err) => {
          this.loading = false;
          this.$func.popMessage(`${err.response.data.error}`, "fail");
        });
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId
    await this.getUser()
    await this.getNotes();
    await this.getDateFormat();
    this.getCurrentClient()
  },
};
</script>
